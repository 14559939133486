"use client";

import React, {
  ChangeEvent,
  DragEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import * as SubframeCore from "@subframe/core";
import { Button } from "@/subframe/components/Button";

function FileUpload({
  selectedFiles,
  setSelectedFiles,
  setSelectedFilesBase64,
  setFilenames,
  setFileURL,
}: {
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setSelectedFilesBase64: React.Dispatch<React.SetStateAction<string[]>>;
  setFilenames: React.Dispatch<React.SetStateAction<string[]>>;
  setFileURL: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleDragEnter = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  }, []);
  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  }, []);
  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    const newFiles = Array.from(event.dataTransfer.files);
    if (newFiles.length) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setFileURL(URL.createObjectURL(newFiles.at(-1)));
      Promise.all(
        newFiles.map((file) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              resolve(
                (reader.result as string)
                  .replace("data:", "")
                  .replace(/^.+,/, ""),
              );
            };
            reader.onerror = (error) => reject(error);
          });
        }),
      )
        .then((newBase64Files) => {
          setSelectedFilesBase64((prevBase64Files) => [
            ...prevBase64Files,
            ...newBase64Files,
          ]);
          setFilenames((prevFilenames) => [
            ...prevFilenames,
            ...newFiles.map((file) => file.name),
          ]);
        })
        .catch((error) => console.log("Error reading files: ", error));
    }
  }, []);

  const uploadContainerStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    border: "1px dashed" + "#0284C7",
    borderRadius: "5px",
    backgroundColor: isDragOver ? "#f0f8ff" : "transparent",
  };

  const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = Array.from(e.target.files || []);
    const selectedFile = newFiles.at(-1);
    if (selectedFile) {
      const newFilenames: string[] = newFiles.map((file) => file.name);
      setFileURL(URL.createObjectURL(selectedFile));
      Promise.all(
        newFiles.map((file) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              resolve(
                (reader.result as string)
                  .replace("data:", "")
                  .replace(/^.+,/, ""),
              );
            };
            reader.onerror = (error) => reject(error);
          });
        }),
      )
        .then((newBase64Files) => {
          setSelectedFiles(() => [selectedFile]);
          setSelectedFilesBase64(() => [...newBase64Files]);
          setFilenames((prevFilenames) => [...prevFilenames, ...newFilenames]);
        })
        .catch((error) => console.log("Error reading files: ", error));
    }
  };

  const removeFile = (indexToRemove: number) => {
    setSelectedFiles((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove),
    );
    setSelectedFilesBase64((currentBase64Files) =>
      currentBase64Files.filter((_, index) => index !== indexToRemove),
    );
    setFilenames((currentFilenames) =>
      currentFilenames.filter((_, index) => index !== indexToRemove),
    );
  };

  return (
    <>
      <div
        style={uploadContainerStyle}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="w-full py-4 flex flex-col items-center justify-center h-64 border-2 border-dashed border-blue-500 rounded"
      >
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="hiddenFileInputAutobench"
          accept="application/pdf"
          ref={fileInputRef}
        />
        <label
          htmlFor="hiddenFileInputAutobench"
          className="text-center cursor-pointer"
          onClick={handleClick}
        >
          <SubframeCore.Icon
            className="text-heading-1 font-heading-1 text-brand-700 mt-4"
            name="FeatherUploadCloud"
          />
          <br />
          Click to select or drag and drop to upload
        </label>
        <div className="p-2" style={{ maxHeight: "50%", overflow: "auto" }}>
          {selectedFiles &&
            selectedFiles.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between mb-1"
              >
                <span>
                  {file.name} - {Math.round(file.size / 1024)} KB
                </span>
                <Button
                  onClick={() => removeFile(index)}
                  className="ml-2"
                  style={{ zIndex: 999 }}
                  icon={"FeatherTrash"}
                  variant={"destructive-secondary"}
                >
                  {""}
                </Button>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default FileUpload;
