import React, { useEffect, useState } from "react";
import { createPart, getSupplierAutocomplete } from "../../network/ApiAxios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { DefaultPageLayout } from "@/subframe/layouts/DefaultPageLayout";
import { Badge } from "@/subframe/components/Badge";
import { DropdownMenu } from "@/subframe/components/DropdownMenu";
import * as SubframeCore from "@subframe/core";
import { TextField } from "@/subframe/components/TextField";
import { TextArea } from "@/subframe/components/TextArea";
import { Button } from "@/subframe/components/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { createSet } from "../../network/ApiAxios.js";
import { useParams } from "react-router-dom";
import SuccessPage from "../Success";

function OrderCreate(setSetId, setSendRFQStatus) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const part_id = searchParams.get("part_id");
  const part_name = searchParams.get("part_name");
  const quantity = searchParams.get("quantity");
  const due_date = searchParams.get("due_date");
  const emails_params = searchParams.getAll("emails");

  const [emails, setEmails] = React.useState<any[]>([]);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [emailInput, setEmailInput] = useState("");

  const [partId, setPartId] = useState(part_id || "");
  const [itemName, setItemName] = useState(part_name || "");
  const [itemQuantity, setItemQuantity] = useState(quantity || "");
  const [dueDate, setDueDate] = useState(due_date || "");
  const [subject, setSubject] = useState("RFQ");
  const [body, setBody] = useState(`Hi,<br /><br />
    Please send pricing for the following:<br /><br /><br/><br/>
    Best Regards,<br />
    <b>Jessica Dimmick</b><br />
    Procurement Buyer, Wisconsin Aluminum Foundry<br />
    <b>Phone</b> 920-686-2746<br />
    <b>Web</b> www.wafco.com <b>Email</b> jdimmick@wafco.com<br />
    838 South 16th Street, Manitowoc WI 54220
    838 South 16th Street, Manitowoc WI 54220
    `);
  const [details, setDetails] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNext = () => {
    setShowEmail(true);
  };

  const handleBack = () => {
    setShowEmail(false);
  };

  const fetchEmailSuggestions = async (query) => {
    try {
      getSupplierAutocomplete(undefined, query)
        .then((response) => {
          console.log(response);
          const { data } = response;
          if (data.success) {
            setEmailSuggestions(data.email_suggestions);
            console.log(data.email_suggestions);
            setDropdownOpen(data.email_suggestions.length > 0); // Open dropdown if there are suggestions
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error("Error fetching email suggestions:", error);
    }
  };

  const handleEmailInputChange = (event) => {
    const value = event.target.value;
    setEmailInput(value);
    console.log("current email input:", value);

    if (value) {
      fetchEmailSuggestions(value);
    } else {
      setEmailSuggestions([]);
      setDropdownOpen(false); // Close dropdown when input is cleared
    }
  };

  const handleEmailSelect = (email) => {
    if (emails.includes(email)) {
      toast.warning("Email already added.");
      return;
    }

    setEmails([...emails, email]);
    setEmailInput(""); // Clear the input after selecting
    setEmailSuggestions([]);
    setDropdownOpen(false); // Close dropdown after selection
  };

  const handleEmailKeyDown = (event) => {
    if (event.key === "Enter" && emailInput.trim()) {
      handleEmailSelect(emailInput.trim());
      event.preventDefault();
    }
  };

  const handleRFQSend = () => {
    if (emails.length < 1) {
      toast.warning("Please enter at least one email address.");
      return;
    }

    console.log("sending to");
    console.log(emails);

    createSet(null, null, emails, subject, body, details)
      .then((res) => {
        toast.success("Quote request sent successfully.");
        console.log("Quote request sent successfully.");

        const { data } = res;
        console.log(data);

        if (data.success) {
          setSetId(data.set_id);
          setSendRFQStatus("success");
        }

        return {
          success: data.success,
          setId: data.set_id,
        };
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          window.open(
            "https://lighthouz.ai/api/procurement/google/login",
            "_blank",
          );
          toast.warning("Please login to your Google account.");
          return;
        }
        toast.error(JSON.stringify(e) || "Error sending RFQ.");
      });
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageBodyChange = (value) => {
    setBody(value);
  };

  return (
    <>
      {/* <DefaultPageLayout>
      <ToastContainer /> */}

      {/* <div className="container max-w-none flex h-full w-full flex-col items-start gap-4 bg-default-background py-12"> */}
      {/* <span className="text-body font-body text-default-font">Start a new RFQ</span> */}

      <div className="flex w-full flex-col items-start gap-2 rounded-md border border-solid border-neutral-border bg-default-background px-4 py-4 shadow-lg">
        <span className="text-body font-body text-default-font">
          From: srijan@lighthouz.ai
        </span>

        <div className="flex w-full items-center gap-2">
          <span className="text-body font-body text-default-font">To:</span>

          <SubframeCore.DropdownMenu.Root
            open={dropdownOpen}
            onOpenChange={setDropdownOpen}
          >
            <SubframeCore.DropdownMenu.Trigger asChild={true}>
              <TextField
                className="h-auto grow shrink-0 basis-0"
                variant="filled"
                label=""
                helpText=""
              >
                <TextField.Input
                  placeholder=""
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  onKeyDown={handleEmailKeyDown}
                />
              </TextField>
            </SubframeCore.DropdownMenu.Trigger>

            {emailSuggestions.length > 0 && (
              <>
                <SubframeCore.DropdownMenu.Portal>
                  <SubframeCore.DropdownMenu.Content
                    side="bottom"
                    align="start"
                    sideOffset={4}
                    asChild={true}
                  >
                    <DropdownMenu>
                      {emailSuggestions.map((suggestion, index) => (
                        <DropdownMenu.DropdownItem
                          icon="FeatherPlus"
                          key={index}
                          onClick={() => handleEmailSelect(suggestion)}
                        >
                          {`${suggestion.contact_person_name} [${suggestion.supplier_name}]: ${suggestion.email}`}
                        </DropdownMenu.DropdownItem>
                      ))}
                    </DropdownMenu>
                  </SubframeCore.DropdownMenu.Content>
                </SubframeCore.DropdownMenu.Portal>
              </>
            )}
          </SubframeCore.DropdownMenu.Root>
        </div>

        {emails.length > 0 && (
          <>
            {emails.map((email, index) => (
              <Button
                iconRight="FeatherXCircle"
                key={index}
                onClick={() => removeEmail(index)}
              >
                {email}
              </Button>
            ))}
          </>
        )}

        <div className="flex w-full items-center gap-2">
          <span className="text-body font-body text-default-font">
            Subject:
          </span>
          <TextField
            className="h-auto grow shrink-0 basis-0"
            variant="filled"
            label=""
            helpText=""
          >
            <TextField.Input
              placeholder=""
              value={subject}
              onChange={handleSubjectChange} // Handle input change
            />
          </TextField>
        </div>

        <div className="h-auto w-full flex-none">
          <ReactQuill
            value={body}
            onChange={handleMessageBodyChange}
            placeholder="Add message body"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link", "image"], // Add image option in toolbar
                ["clean"], // Remove formatting button
              ],
            }}
          />
        </div>
        <Button onClick={handleRFQSend}>Send</Button>
      </div>
      {/* </div> */}
      {/* </DefaultPageLayout> */}
    </>
  );
}

export default OrderCreate;
